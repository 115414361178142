import Popup from "@/components/Popup.vue"
import Vue from "vue"

/**
 * 创建弹出层,将封装的AddAndModify组件挂载到页面
 * @param el {string} 模板
 * @param setTree {Object} 集合树
 * @param mode {String} 方式
 * @param fun {function} 回调方法
 * @param callback {function} 回滚方法
 */
export default function (el, setTree, mode, fun, callback = null){
  let div = document.createElement("div")
  div.id = "popup"
  document.body.appendChild(div)
  Vue.prototype.$_el = el
  Vue.prototype.$setTree = setTree
  Vue.prototype.$mode = mode
  Vue.prototype.$fun = fun
  Vue.prototype.$callback = callback
  // /**
  //  * 关闭弹出层，销毁组件实例
  //  */
  // Vue.prototype.$closePopup = function (){
  //   document.querySelector(".popup").remove()
  //   console.log(this)
  //   this.$destroy()
  // }
  new Vue({
    render: h => h(Popup),
  }).$mount("#popup")
}
