<script>
import {mapState, mapMutations} from "vuex";
import Md5 from "js-md5";
export default {
  created() {
    if (this.token == null || this.token.length === 0) {
      this.$router.push("/login");
    }
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState({
      catch_components: (state) => state.catch_components, // keepalive缓存
    }),
    token() {
      return sessionStorage.getItem("sessionToken");
    },
    /**
     * 计算当前选中项的位置
     * @return {string}
     */
    styleCalc(){
      return `left: ${160 * this.active + 20}px`
    },
    active(){
      return this.$store.state.steps.menu
    }
  },
  methods: {
    /**
     * 切换菜单项
     * @param e
     */
    switchItems(e){
      this.$store.commit("updateMenu", parseInt(e.target.dataset.number))
    },
    /**
     * 退出登录
     */
    exitLogin(){
      this.$confirm('确定要退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post(this.$api.publicUrl.ExitLogin).then(res =>{
          if(res.data.status === 200){
            this.$message({
              type: 'success',
              message: '退出成功!'
            });
            this.$store.commit("delUser")
            sessionStorage.removeItem("sessionToken")
            this.$router.push("/login")
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    /**
     * 修改个人密码
     */
    updatePassWord() {
      this.$prompt('此操作需要输入当前登录账户密码，才能继续！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.axios.post(this.$api.publicUrl.VerifyPassWord, Md5(value), {
            headers: {
              'Content-Type': 'text/plain'
            }
        }).then(res =>{
          if(res.data.status === 200){
            this.$message.success("验证成功，请输入新的密码！")
            this.$prompt('请输入新密码！', '提示', {
              inputType: "password",
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(({ value }) => {
              this.axios.put(this.$api.publicUrl.UpdatePassWord, Md5(value), {
                headers: {
                  'Content-Type': 'text/plain'
                }
              }).then(res =>{
                if(res.data.status === 200){
                  this.$message.success("密码修改成功，请重新登录")
                  this.$store.commit("delUser")
                  sessionStorage.removeItem("sessionToken")
                  this.$router.push("/login")
                }else {
                  this.$message.error("密码错误")
                }
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '取消更改'
              });
            });
          } else {
            this.$message.error("密码错误")
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消更改'
        });
      });
    }
  },
};
</script>
<template>
	<div class="app">
		<nav>
			<div class="nav_title">
        <div class="logo">
          <img :src="require('@/assets/images/logo.png')" alt="驴呗出行"/>
        </div>
        <p>驴呗出行</p>
			</div>
      <div id="top_Nav">
        <ul class="show-dropdown main-navbar" @click="switchItems">
          <li class="selector-active" :style="styleCalc">
            <div class="left"></div>
            <div class="right">
            </div>
          </li>
          <router-link :to="{name: 'order'}" tag="li" data-number= 0 :class="{'active': active === 0}"><span>订单管理</span></router-link>
          <router-link :to="{name: 'vehicle'}" tag="li" data-number= 1 :class="{'active': active === 1}"><span>车辆管理</span></router-link>
          <router-link :to="{name: 'statistics'}" tag="li" data-number= 2 :class="{'active': active === 2}"><span>数据统计</span></router-link>
          <router-link :to="{name: 'user'}" tag="li" data-number= 3 :class="{'active': active === 3}"><span>用户管理</span></router-link>
          <router-link :to="{name: 'configuration'}" tag="li" data-number= 4 :class="{'active': active === 4}"><span>系统配置</span></router-link>
        </ul>
      </div>
      <div class="user" v-if="this.$store.state.user">
        <div class="user_img">
          <img :src="require('@/assets/images/user.png')" alt="用户头像"/>
        </div>
			  <p class="user_name">{{this.$store.state.user.userName}}</p>
        <div class="control">
          <ul>
            <li @click="updatePassWord">更换密码</li>
            <li @click="exitLogin">退出登录</li>
          </ul>
        </div>
			</div>
		</nav>
    <section>
        <router-view />
    </section>
	</div>
</template>


<style scoped>
.update_pass{

}
.update_pass input{
  padding: 10px;
}
.app{
  height: 100%;
  width: 100%;
}
section{
  overflow: hidden;
  height: 100%;
}
nav{
  display: flex;
  background-color: #358F6C;
  height: 70px;
  align-items: center;
  padding: 0 20px;
  font-size: 20px;
  color: white;
}
.nav_title{
  display: flex;
  width: 220px;
  min-width: 220px;
  align-items: center;
  justify-content: space-between;
}
.logo{
  background-color: white;
  border-radius: 30px;
  width: 120px;
  height: 48px;
  text-align: center;
}
.logo img{
  transform: translateY(-10px);
}
.user{
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}
.user:hover .control{
  height: 82px;
}
.user p {
  margin-left: 10px;
}
.user_img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.user_img img{
  width: 50px;
  height: 50px;
  transition: transform 0.5s;
}
.user_img img:hover{
  transform: scale(1.3);
}
.control ul li:hover{
  background: #358F6C;
  color: white;
}
.control{
  position: absolute;
  z-index: 10;
  background-color: white;
  top: 50px;
  color: black;
  transition: all 0.5s;
  border-radius: 5px;
  height: 0;
  overflow: hidden;
}
.control ul li{
  padding: 10px 20px;
}

section{
 height: 100%;
}
#top_Nav{
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 10px;
}
#top_Nav ul{
  display: flex;
  height: 100%;
  position: relative;
}
#top_Nav ul li{
  cursor: pointer;
  text-align: center;
}
#top_Nav ul li span{
  color: rgba(255,255,255,0.5);
  text-decoration: none;
  font-size: 16px;
  line-height: 45px;
  display: block;
  padding: 0 20px;
  transition-duration:0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
  width: 120px;
  pointer-events: none;
  user-select: none;
}
#top_Nav ul li:hover span{
  color: white;
}
#top_Nav>ul>li.active>span{
  color: #358F6C;
  background-color: transparent;
  transition: all 0.7s;
}
#top_Nav span:not(:only-child):after {
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 10%;
  font-size: 14px;
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.5s;
}
#top_Nav .active>span:not(:only-child):after {
  transform: rotate(90deg);
}
.selector-active{
  display:inline-block;
  position:absolute;
  height: 100%;
  width: 120px;
  top: 0;
  left: 20px;
  transition-duration:0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  pointer-events: none;
}
.selector-active .left, .selector-active .right{
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  bottom: 0;
}
.selector-active .left{
  left: -25px;
}
.selector-active .right{
  right: -25px;
}
.selector-active .left:before, .selector-active .right:before{
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 20%;
  background-color: #358F6C;
  bottom: 0;
}
.selector-active .left:before{
  right: 0;
}
.selector-active .right:before{
  left: 0;
}

</style>
