// let rootPath = "http://192.168.3.104:16001"; //测试环境
// let rootPath = "https://ql.smlake.cn"; //测试环境
// let rootPath = "https://lvbei.smlake.cn"; //正式环境
// let rootPath = "http://192.168.100.108:7711"
let rootPath = ""


let publicUrl =  {
    // 用户
    UserLogin: rootPath + "/web/api/user/login", //登录
    ExitLogin: rootPath + "/web/api/user/logout",  //退出登录
    UpdatePassWord: rootPath + "/web/api/user/edit/password",  //修改密码
    VerifyPassWord: rootPath + "/web/api/user/password/check",  //验证密码
    UpdateUserStatus: rootPath + "/web/api/user/edit/status", //修改账号状态
    UpdateUserInfo: rootPath + "/web/api/user/edit/user", //修改用户信息
    //系统管理
    BlackList: rootPath + "/web/api/user/black",  //黑名单
    DictList: rootPath + "/web/api/dictionary", //字典
    ExpenseList: rootPath + "/web/api/cost/type",  //费用项
    //用户
    UserList: rootPath + "/web/api/user/list",  //用户
    //统计
    OrderStat: rootPath + "/web/api/stats/order",  //订单统计
    ExpenseStat: rootPath + "/web/api/stats/cost", //费用统计
    NewExpenseStat: rootPath + "/web/api/stats/newCost",  //新的费用统计
    CarStat: rootPath + "/web/api/stats/car/invent/count",  //车辆库存统计
    OrderExport: rootPath + "/web/api/stats/orderExport", //订单导出
    ExpenseExport: rootPath + "/web/api/stats/costExport",  //费用导出
    CarExport: rootPath + "/web/api/stats/inventExport",  //车辆导出
    //车辆
    Maintenance: rootPath + "/web/api/maintenance", //保养记录
    VehicleList: rootPath + "/web/api/car", //车辆列表
    VehicleInfo: rootPath + "/web/api/car/cascader", // 车辆信息级联
    DelVehicle: rootPath + "/web/api/car",  //删除车辆

    //订单
    OrderList: rootPath + "/web/api/order", //全部订单列表
    BasicInfo: rootPath + "/web/api/order/detail", //基本信息
    UpdateInfo: rootPath + "/web/api/order",  //修改基本信息
    HandoverInfo: rootPath + "/web/api/order/handover",  //交车信息
    ReplaceInfo: rootPath + "/web/api/order/change",  //换车信息
    RenewalPage: rootPath + "/web/api/order/orderDelayByDay",
    ReplacePage: rootPath + "/web/api/order/change/page",
    BackCarInfo: rootPath + "/web/api/order/return",  //还车信息
    RenewalInfo: rootPath + "/web/api/order/delay",  //续租信息
    CostInfo: rootPath + "/web/api/order/cost",  //费用信息
    UpdateHandover: rootPath + "/web/api/order/handover",  //修改交车信息
    UpdateBackCar: rootPath + "/web/api/order/return",  //修改还车信息
    UpdateReplace: rootPath + "/web/api/order/change", //修改换车信息
    UpdateRenewal: rootPath + "/web/api/order/delay", //修改续租信息
    UpdateCost: rootPath + "/web/api/order/cost", //修改费用信息
    DelCost: rootPath + "/web/api/order/cost", //删除费用信息
    ContractInfo: rootPath + "/web/api/contract/", //合同信息

};
//深拷贝
let deepClone = function(target) {
	let result;
	if (typeof target === "object") {
		if (Array.isArray(target)) {
			result = [];
			for (let i in target) {
				result.push(deepClone(target[i]));
			}
		} else if (target === null) {
			result = null;
		} else if (target.constructor === RegExp) {
			result = target;
		} else {
			result = {};
			for (let i in target) {
				result[i] = deepClone(target[i]);
			}
		}
	} else {
		result = target;
	}
	return result;
};
let dateToString = function(date) {
	const year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let minute = date.getMinutes();
	let second = date.getSeconds();
	month = month > 9 ? month : "0" + month;
	day = day > 9 ? day : "0" + day;
	hour = hour > 9 ? hour : "0" + hour;
	minute = minute > 9 ? minute : "0" + minute;
	second = second > 9 ? second : "0" + second;
	const dateTime =
		year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
	return dateTime;
};
export default {
	publicUrl: publicUrl,
	deepClone,
	dateToString,
};
