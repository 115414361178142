<script>
  export default {
    props: ["data", "bond"],
    methods: {
      handleValue(value){
        this.$emit('sendValue', this.bond, value)
      }
    }
  }
</script>

<template>
  <el-switch
    style="display: block"
    :value="data"
    @change="handleValue"
    active-color="#13ce66"
    inactive-color="#ff4949"
    :active-value= 1
    :inactive-value= 0
    active-text="启用"
    inactive-text="禁用">
  </el-switch>
</template>
