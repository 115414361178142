<script>
export default {
  props: ["data", "format"],
  computed:{
    dataFormat(){
      if(!this.format) return this.data
      switch (this.format){
        case "yyyy-mm-dd":
          return this.formatDate(new Date(this.data))
        case "paymentMethod":
          return this.formatPaymentMethod(this.data)
        case "paymentStatus":
          return this.formatPaymentStatus(this.data)
        case "level":
          return this.formatUserLevel(this.data)
        case "orderStatus":
          return this.formOrderStatus(this.data)
        default:
          return this.data
      }
    }
  },
  methods: {
    formatDate(date){
      let year = date.getFullYear();
      let month = date.getMonth()+1;
      let day = date.getDate();
      month = month < 10 ?'0'+ month : month;
      day = day < 10 ?'0'+ day : day;
      return year +'-'+ month +'-'+ day;
    },
    formatPaymentMethod(data){
      switch (data){
        case 0:
          return "无"
        case 1:
          return "线上"
        case 2:
          return "线下公账"
        case 3:
          return "线下私账"
      }
    },
    formatPaymentStatus(data){
      if(data){
        return "已到账"
      }else {
        this.$nextTick(()=>{
          this.$refs.tab_p.className = "dangerous"
        })
        return "未到账"
      }
    },
    formatUserLevel(data){
      if(data === 1){
        return "管理端账户"
      }else if (data === 100) {
        return "运维端工作账户"
      }else {
        return "运维端维修账户"
      }
    },
    formOrderStatus(data){
      this.$nextTick(()=>{
        this.$refs.tab_p.setAttribute("data-type", data)
      })
      switch (data){
        case 0:
          return "已取消"
        case 3:
          return "待取车"
        case 4:
          return "用车中"
        case 5:
          this.$nextTick(()=>{
            this.$el.parentElement.parentElement.classList.remove("timed_out")
          })
          return "已还车"
      }
    }
  },
}
</script>

<template>
  <p ref="tab_p">{{dataFormat}}</p>
</template>

<style scoped lang="less">
  p{
    margin: 0;
  }
  .dangerous{
    color: red;
    font-weight: 600;
  }
  p[data-type="0"]{
    color: #a9aba7;
  }
  p[data-type="3"]{
    color: #e7d370;
  }
  p[data-type="4"]{
    color: #6397c2;
  }
  p[data-type="5"]{
    color: #44d762;
  }
</style>
