<script>
export default {
  props: ["data", "type", "bond"],
  methods:{
    handleValue(value){
      this.$emit('sendValue', this.bond, value)
    }
  }
}
</script>

<template>
  <el-input :type="type" :value="data" @input="handleValue"></el-input>
</template>

<style scoped>

</style>
