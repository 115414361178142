var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('fragment',[_c('div',{staticClass:"main"},[_c('div',{staticClass:"items"},[_c('p',{staticClass:"title tx"},[_vm._v("续租记录")])]),_c('div',{staticClass:"items"},[_c('p',[_vm._v("续租次数："),_c('span',[_vm._v(_vm._s(_vm.data.length))])])])]),_c('div',{staticClass:"detail segment scroll"},[_c('Tabulate',{attrs:{"data":{
      columnName: [
        // {name: '续租费用', prop: 'chargeAmount', type: 'text', needSort: false, style: 'min-width: 100px; flex: 0'},
        {name: '续租前还车时间', prop: 'returnTimeBefore', type: 'text', needSort: false, style: 'min-width:180px'},
        {name: '续租后还车时间', prop: 'returnTimeAfter', type: 'text', needSort: false, style: 'min-width:180px'},
        {name: '续租截图', prop: 'certificateImage', type: 'img', needSort: false, style: 'min-width: 100px; flex: 0'},
        {name: '操作时间', prop: 'createTime', type: 'text', needSort: false, style: 'min-width:180px'},
        {name: '备注', prop: 'remark', type: 'text', needSort: false},
      ],
      rows: this.data,
      control: [
        // {name: '修改', type: 'warning', method: this.createDrawer},
      ]
    }}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }