<script>
  export default {
    props: ["data", "control"],
    methods: {
      btnClick(){
        if(this.control.name === "修改"){
          this.control.method(this.$lodash.cloneDeep(this.data))
        }else if(this.control.name === "删除"){
          this.control.method(this.data.id)
        }else if(this.control.name === "查看详情"){
          this.control.method(this.data)
        }else if(this.control.name === "重置密码"){
          this.control.method()
        }
      }
    }
  }
</script>

<template>
  <el-button @click="btnClick" size="small" :type="control.type">{{control.name}}</el-button>
</template>

