<script>
export default {
  props: ["data", "type", "bond"],
  methods:{
    handleValue(value){
      this.$emit('sendValue', this.bond, value)
    }
  }
}
</script>

<template>
  <el-radio-group :value="data" @input="handleValue">
    <el-radio :label="1">有</el-radio>
    <el-radio :label="0">没有</el-radio>
  </el-radio-group>
</template>
