<script>
import Tabulate from "@/view/tabulate/Tabulate.vue";
import {calc} from "@/utils/calc"
export default {
  components: {Tabulate},
  props: ["data", "updateCallBack"],
  created() {
    this.axios.get(this.$api.publicUrl.ExpenseList).then(res =>{
      this.expenseList = []
      if(res.data.status === 200){
        res.data.data.forEach(item =>{
          let obj = {}
          obj.value = item.costName
          obj.label = item.costName
          this.expenseList.push(obj)
        })
      }
    })
    this.axios.get(this.$api.publicUrl.UserList, {
      params: {
        pageSize: 0,
        pageNum: 1,
      }
    }).then(res =>{
      this.userList = []
      if(res.data.status === 200){
        res.data.data.list.forEach(item =>{
          let obj = {}
          obj.value = item.id
          obj.label = item.userName
          this.userList.push(obj)
        })
      }
    })
  },
  computed:{
    totalCost(){
      let sum = 0
      if(this.data) {
        this.data.forEach(obj =>{
          sum = calc(sum, obj.costAmount)
        })
      }
      return sum
    },
    arrears(){
      let sum = 0
      if(this.data){
        this.data.forEach(obj =>{
          if(!obj.costStatus){
            sum += obj.costAmount
          }
        })
      }
      return sum? sum : "无"
    }
  },
  methods:{
    updateRow(row){
      let {createTime, editorId, editorName,orderId, operatorName,costImage,updateTime, ...rest} = row
      this.createDrawer(this.$lodash.cloneDeep(rest))
    },
    delCost(id){
      this.updateCallBack(id, "删除费用")
    },
    /**
     * 创建二级弹出层Drawer, 以及创建数据模板
     */
    createDrawer(data){
      this.$createSecondaryPopup({
          ti: "费用记录修改",
          tree: [
            [{label: "费用名称", type: "select", key: "costName", prop: "costName", options: this.expenseList, class: "mini"}],
            [{label: "操作员", type: "select", key: "operatorId", prop: "operatorId", options: this.userList, class: "mini"}],
            [{label: "金额", type: "number", key: "costAmount", prop: "costAmount", class: "mini"}],
            [{label: "费用状态", type: "select", options: [
                {value: 0, label: "未到账"},
                {value: 1, label: "已到账"},
              ], key: "costStatus", prop: "costStatus", class: "mini"}],
            [{label: "付款方式", type: "select", options: [
                {value: 0, label: "无"},
                {value: 1, label: "线上"},
                {value: 2, label: "线下公账"},
                {value: 3, label: "线下私账 "}
              ], key: "paymentType", prop: "paymentType", class: "mini"}],
            [{label: "备注", type: "textarea", key: "remark", prop: "remark", class: "mini"}],
          ],
          formData: data,
          rules: {
            chargeAmount: [{ required: true, message: '请选择费用', trigger: 'change' }],
            operatorId: [{ required: true, message: '请选择操作员', trigger: 'change' }],
            costAmount: [{ required: true, message: '请输入金额', trigger: 'blur' }],
            paymentType: [{ required: true, message: '请选择付款方式', trigger: 'change'}],
            costStatus: [{ required: true, message: '请选择费用状态', trigger: 'change'}],
          }
        }
        , "费用", this.updateCallBack)
    }
  }
}
</script>

<template>
  <fragment>
    <div class="main">
      <div class="items">
        <p class="title tx">费用记录</p>
      </div>
      <div class="items">
        <p>费用项数：<span>{{data? data.length : "无"}}</span></p>
        <p>总费用：{{totalCost}}</p>
        <p>未到账：{{arrears}}</p>
      </div>
    </div>
    <div class="detail segment" v-if="data">
      <Tabulate :data="{
        columnName: [
          {name: '费用名称', prop: 'costName', type: 'text', needSort: false, style: 'flex: 1.5'},
          {name: '费用金额', prop: 'costAmount', type: 'text', needSort: false, style: 'min-width: 100px; flex: 0'},
          {name: '支付状态', prop: 'costStatus', type: 'text', needSort: false, style: 'min-width: 100px; flex: 0', format: 'paymentStatus'},
          {name: '付款方式', prop: 'paymentType', type: 'text', needSort: false, style: 'min-width: 100px; flex: 0', format: 'paymentMethod'},
          {name: '创建时间', prop: 'createTime', type: 'text', needSort: false, style: 'flex: 1.5'},
        ],
        rows: this.data,
        control: [
          {name: '修改', type: 'warning', method: this.updateRow},
          {name: '删除', type: 'danger', method: this.delCost}
        ]
      }"></Tabulate>
    </div>
  </fragment>
</template>

<style src="@/assets/css/Popup.css" scoped></style>
