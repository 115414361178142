<script>
export default {
  props: ["data", "options", "bond"],
  data(){
    return {
      value: this.data || ""
    }
  },
  methods: {
    handleChange(value){
      this.$emit('sendValue', this.bond, value)
    }
  }
}
</script>

<template>
  <el-cascader
    v-model="value"
    :options="options"
    @change="handleChange"></el-cascader>
</template>

<style scoped lang="less">

</style>
