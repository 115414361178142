<script>
export default {
  props: ["data", "options", "bond"],
  methods:{
    handleValue(value){
      this.$emit('sendValue', this.bond, value)
    }
  }
}
</script>

<template>
<el-select :value="data" @change="handleValue" filterable allow-create default-first-option>
  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
</el-select>
</template>

<style scoped lang="less">

</style>
