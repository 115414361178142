<script>
import OrderDetails from "@/view/queryOrder/child/OrderDetails.vue";
import StartDetails from "@/view/queryOrder/child/StartDetails.vue";
import BackCarDetails from "@/view/queryOrder/child/BackCarDetails.vue";
import ReplaceDetails from "@/view/queryOrder/child/ReplaceDetails.vue";
import RenewalDetails from "@/view/queryOrder/child/RenewalDetails.vue";
import CostDetails from "@/view/queryOrder/child/CostDetails.vue";
import ContractDetails from "@/view/queryOrder/child/ContractDetails.vue";

export default {
  props: ["data","height", "entry", "disable", "ti", "updateCallBack"],
  data(){
    return{
      expandState: false
    }
  },
  computed:{
    CalculationStyle(){
      // return this.expandState? `height: ${this.height !== ""? this.height + "px" : "100%"}` : null
      return this.expandState? `max-height: 1000px` : null
    },
    infoFormat(){
      return [this.data.reserveCarBrand, this.data.reserveCarSeries]
    }
  },
  created() {
    if(this.entry === "cost"){
      /**
       * 请求费用项列表
       */
      this.axios.get(this.$api.publicUrl.ExpenseList).then(res =>{
        this.expenseList = []
        if(res.data.status === 200){
          res.data.data.forEach(item =>{
            let obj = {}
            obj.value = item.costName
            obj.label = item.costName
            this.expenseList.push(obj)
          })
        }
      })
      this.axios.get(this.$api.publicUrl.UserList, {
        params: {
          pageSize: 0,
          pageNum: 1,
        }
      }).then(res =>{
        this.userList = []
        if(res.data.status === 200){
          res.data.data.list.forEach(item =>{
            let obj = {}
            obj.value = item.id
            obj.label = item.userName
            this.userList.push(obj)
          })
        }
      })
    } else if(this.entry === "basic"){
      //获取车辆信息
      this.axios.get(this.$api.publicUrl.VehicleInfo).then(res =>{
        if(res.data.status === 200){
          this.VehicleInfo = res.data.data
          for(let x = 0; x < this.VehicleInfo?.length; x++){
            for(let y = 0; y < this.VehicleInfo[x].children?.length; y++){
              this.VehicleInfo[x].children[y].children = null
            }
          }
        }else {
          this.$message(res.data.message)
        }
      })
    }
  },
  methods:{
    selectComponent(entry){
      switch (entry){
        case "basic":
          return OrderDetails
        case "start":
          return StartDetails
        case "back":
          return BackCarDetails
        case "replace":
          return ReplaceDetails
        case "renewal":
          return RenewalDetails
        case "cost":
          return CostDetails
        case "contract":
          return ContractDetails
      }
    },
    openAdvancedQuery(){
      this.expandState = !this.expandState
    },
    /**
     * 创建二级弹出层Drawer, 以及创建数据模板
     */
    createDrawer(){
      let setTree
      switch (this.ti){
        case "信息":
          setTree = {
            ti: "基本信息修改",
            tree: [
              [{label: "预定车辆", type: "Cascader", key: "carInfo", prop: "carInfo", options: this.VehicleInfo, class: "mini"}],
              [{label: "用户姓名", type: "text", key: "userName", prop: "userName", class: "mini"}],
              [{label: "联系电话", type: "text", key: "phoneNumber", prop: "phoneNumber", class: "mini"}],
              [{label: "订单来源", type: "select", key: "orderSource", prop: "orderSource", options:[
                  {value: '哈啰', label: '哈啰'},
                  {value: '携程', label: '携程'},
                  {value: '飞猪', label: '飞猪'},
                  {value: '租租车', label: '租租车'},
                  {value: '同行', label: '同行'},
                  {value: '线下', label: '线下'},
                ], class: "mini"}],
              [{label: "保险", type: "select", key: "orderInsurance", prop: "orderInsurance", options: [
                  {value: '无', label: '无'},
                  {value: '平台-基础服务', label: '平台-基础服务'},
                  {value: '平台-优享服务', label: '平台-优享服务'},
                  {value: '平台-尊享服务', label: '平台-尊享服务'},
                  {value: '线下购买', label: '线下购买'},
                ], class: "mini"}],
              [{label: "备注", type: "textarea", key: "orderRemark", prop: "orderRemark", class: "mini"}],
            ],
            formData:{
              id: this.data.id,
              carInfo: this.infoFormat,
              userName: this.data.userName,
              phoneNumber: this.data.phoneNumber,
              orderSource: this.data.orderSource,
              orderInsurance: this.data.orderInsurance,
              orderRemark: this.data.orderRemark
            },
            rules: {}
          }
          break
        case "发车":
          setTree = {
            ti: "发车信息修改",
            tree: [
              [{label: "公里数", type: "text", key: "verificationMileage", prop: "verificationMileage", class: "mini"}],
              [{label: "行车证", type: "radio", key: "drivingLicense", prop: "drivingLicense", class: "mini"}],
              [{label: "备注", type: "textarea", key: "remark", prop: "remark", class: "mini"}],
            ],
            formData:{
              id: this.data.id,
              verificationMileage: this.data.verificationMileage,
              drivingLicense: this.data.drivingLicense,
              remark: this.data.remark,
            },
            rules: {
              verificationMileage: [{ required: true, message: '请输入公里数', trigger: 'blur' }],
              drivingLicense: [{ required: true, message: '请输入行车证', trigger: 'blur' }],
            }
          }
          break
        case "还车":
          setTree = {
            ti: "还车信息修改",
            tree: [
              [{label: "公里数", type: "text", key: "verificationMileage", prop: "verificationMileage", class: "mini"}],
              [{label: "备注", type: "textarea", key: "remark", prop: "remark", class: "mini"}],
            ],
            formData:{
              id: this.data.id,
              verificationMileage: this.data.verificationMileage,
              remark: this.data.remark,
            },
            rules: {
              verificationMileage: [{ required: true, message: '请输入公里数', trigger: 'blur' }]
            }
          }
          break
        case "新增费用":
          setTree = {
            ti: "添加费用信息",
            tree: [
              [{label: "费用名称", type: "select", key: "costName", prop: "costName", options: this.expenseList, class: "mini"}],
              [{label: "操作员", type: "select", key: "operatorId", prop: "operatorId", options: this.userList, class: "mini"}],
              [{label: "金额", type: "number", key: "costAmount", prop: "costAmount", class: "mini"}],
              [{label: "费用状态", type: "select", options: [
                  {value: 0, label: "未到账"},
                  {value: 1, label: "已到账"},
                ], key: "costStatus", prop: "costStatus", class: "mini"}],
              [{label: "付款方式", type: "select", options: [
                  {value: 0, label: "无"},
                  {value: 1, label: "线上"},
                  {value: 2, label: "线下公账"},
                  {value: 3, label: "线下私账 "}
                ], key: "paymentType", prop: "paymentType", class: "mini"}],
              [{label: "备注", type: "textarea", key: "remark", prop: "remark", class: "mini"}],
            ],
            formData:{
              orderId: this.$parent.orderId,
              operatorId: "",
              costName: "",
              costAmount: "",
              paymentType: "",
              costStatus: "",
              remark: "",
            },
            rules: {
              costName: [{ required: true, message: '请选择费用名称', trigger: 'change' }],
              operatorId: [{ required: true, message: '请选择操作员', trigger: 'change' }],
              costAmount: [{ required: true, message: '请输入金额', trigger: 'blur' }],
              paymentType: [{ required: true, message: '请选择付款方式', trigger: 'change' }],
              costStatus: [{ required: true, message: '请选择费用状态', trigger: 'change' }]
            }
          }
          break
      }
      this.$createSecondaryPopup(setTree, this.ti, this.updateCallBack)
    }
  },
  render(h, context) {
    return h("div", {
      class : "skeleton_content",
      style: this.CalculationStyle
    }, [
      h("div", {
        class: "control"
      }, [
        h("el-button", {
          attrs:{
            type: this.ti === "新增费用"? "primary" : "warning",
            size: "mini"
          },
          class: {
            disable: this.disable
          },
          on: {
            click: this.createDrawer
          }
        }, this.ti === "新增费用"? "新增" : "修改"),
        h("div", {
          class: {
            "expand_btn": true,
            "expand_state": this.expandState
          },
          on: {
            click: this.openAdvancedQuery
          }
        }, "展开")
      ]),
      h(this.selectComponent(this.entry), {
        props: {
          data: this.data,
          updateCallBack: this.updateCallBack
        }
      })
    ])
  },
}
</script>

<style scoped lang="less">
.skeleton_content{
  position: relative;
  overflow: hidden;
  max-height: 60px;
  transition: all .5s;
}
.control{
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
}
.control button{
  margin-right: 15px;
}
.expand_btn{
  background-color: transparent;
  color: #358F6C;
  cursor: pointer;
}
.expand_btn::after{
  content: "";
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-left: 5px;
  background-image: url("../../../assets/images/drop-down.png");
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.5s;
  transform: rotate(0);
}
.expand_state::after{
  transform: rotate(180deg);
}
.disable{
  display: none;
}
</style>
