import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
// 引入全局样式
import './assets/css/global.css'
// 引入字体图标
import 'font-awesome/css/font-awesome.min.css'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Fragment from "vue-fragment"
import VueAxios from 'vue-axios'
import axios from 'axios'
import service from './utils/service'
import initial from './initial/initialData'
import createPopup from "@/utils/createPopup";
import createSecondaryPopup from "@/utils/createSecondaryPopup";
import lodash from "lodash"
import *as echarts from 'echarts'

import {
	MessageBox,
	Message
} from 'element-ui'


Vue.use(Element)
Vue.use(Fragment.Plugin)
Vue.use(VueAxios, axios)
Vue.prototype.$api = service
Vue.prototype.$initial = initial
Vue.prototype.$createPopup = createPopup
Vue.prototype.$createSecondaryPopup = createSecondaryPopup
Vue.prototype.$lodash = lodash
Vue.prototype.$echarts = echarts

//axios请求头中的content-type指定类型
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post['Content-Type'] = 'application/json';
//aixos拦截器
axios.interceptors.request.use(config => {
	if (config.url.indexOf("/admin/login") === -1) {
		config.headers.common['Authorization'] = sessionStorage.getItem('sessionToken') || ''
	}
	return config;
}, error => {
	return Promise.reject(error);
})
//aixos拦截器
// 是否显示重新登录
export let isRelogin = {
	show: false
};
axios.interceptors.response.use(res => {
	if (res.data.status === 40001) {
		if (!isRelogin.show) {
			isRelogin.show = true;
			MessageBox.confirm('登录已失效，请重新登录！', '提示', {
				confirmButtonText: '确定',
				type: 'warning'
			}).then(() => {
				isRelogin.show = false;
				// store.commit("clearVUEX");
				sessionStorage.removeItem("sessionToken", "")
				router.push("/login");
			}).catch(() => {
				isRelogin.show = false;
			})
			return Promise.reject('error')
		}
	}else if(res.data.status === 40002){
    Message.error("账号或密码")
  } else{
		return res
	}
}, error => {
	return Promise.reject(error);
})

Vue.config.productionTip = false

new Vue({
	router,
	store, //store:store 和router一样，将创建的Vuex实例挂载到这个vue实例中
	render: h => h(App)
}).$mount('#app')
