<script>
import pubSub from "pubsub-js";
import AddAndModify from "@/view/form/AddAndModify.vue";
import QueryOrder from "@/view/queryOrder/QueryOrder.vue"
export default {
  methods: {
    importTemplate(el, h){
      switch (el){
        case "form":
          return h(AddAndModify, {
            props: {

            }
          })
        case "queryOrder":
          return h(QueryOrder)
      }
    },
    /**
     * 关闭弹出层，销毁组件实例
     */
    closePopup(){
      document.querySelector(".popup").remove()
      this.$destroy()
      pubSub.publish("dis")
    }
  },
  render(h, context) {
    return h("div", {
      class: "popup",
    }, [
      h("div", {
        class: "mask"
      }),
      h("div", {
        class: "content"
      }, [
        h("div", {
          class: "title"
        }, [
          h("h2", this.$setTree.ti),
          h("div", {
            class: "close",
            on: {
              click: this.closePopup
            }
          })
        ]),
        h("section", {
          class: "custom"
        }, [
          this.importTemplate(this.$_el, h)
        ])
      ])
    ])
  }
}
</script>

<style scoped>
.popup{
  position: absolute;
  inset: 0;
  z-index: 10;
}
.mask{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.title{
  text-align: left;
  background-color: #358f6c;
  color: white;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom{
  background-color: white;
  padding: 20px 10px 20px 20px;
  max-height: 700px;
  overflow-y: scroll;
  position: relative;
}
.close{
  height: 50px;
  width: 50px;
  background: url("../assets/images/close.png") no-repeat;
  background-size: cover;
  cursor: pointer;
  transform: rotate(0);
  transition: transform 1s;
}
.close:hover{
  transform: rotate(360deg);
}

</style>
