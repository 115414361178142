<script>
import Skeleton from "@/view/queryOrder/child/Skeleton.vue";

export default {
  components: {Skeleton},
  data(){
    return{
      step: 0,
      costWait: false,
      orderId: this.$setTree.id,
      BasicInfo: this.$setTree.basicInfo,  //基本信息
      HandoverInfo: null,  //发车信息
      BackCarInfo: null,  //还车信息
      ReplaceInfo: null,  //换车信息
      RenewalInfo: null,  //续租信息
      CostInfo: null, //费用信息
      ContractInfo: null,  //合同信息,
    }
  },
  created() {
    this.getData([
      {api: "HandoverInfo", msg: "交车信息"},
      {api: "BackCarInfo", msg: "还车信息"},
      {api: "ReplaceInfo", msg: "换车信息"},
      {api: "RenewalInfo", msg: "续租信息"},
      {api: "CostInfo", msg: "费用信息"},
    ])
    //获取操作员列表
    this.axios.get(this.$api.publicUrl.UserList, {
      params: {
        pageSize: 0,
        pageNum: 1,
      }
    }).then(res =>{
      if(res.data.status === 200){
        this.userList = res.data.data.list
      }
    })

  },
  methods:{
    getData(arr){
      this.axios.get(this.$api.publicUrl.ContractInfo + this.BasicInfo.orderSn).then(res =>{
        if(res.data.status === 200){
          this.ContractInfo = res.data.data
          this.step++
        }
      })
      arr.forEach(item =>{
        new Promise((resolve, reject)=>{
          this.axios.get(this.$api.publicUrl[item.api], {
            params: {
              orderId: this.orderId
            }
          }).then(res =>{
            if (res.data.status === 200){
              if(Array.isArray(res.data.data)){
                if(item.api === "CostInfo"){
                  this.costWait = true
                }
                this[item.api] = res.data.data.length? res.data.data : null
              }else {
                this[item.api] = res.data.data
              }
              this.step++
              resolve(item.msg)
            }else {
              reject(item.msg)
            }
          })
        }).then(res=>{
        }).catch(err =>{
          this.$message.error(err)
        })
      })
    },
    /**
     * 二级弹出层事件回调, 这里可能需要做数据预处理
     */
    secondaryPopupCallBack(data, mod){
      this.dataPreprocessing(data, mod)
    },
    /**
     * 数据预处理
     * @param data
     * @param mod
     */
    dataPreprocessing(data, mod){
      switch (mod){
        case "新增费用":
          for (let i = 0; i < this.userList.length; i++){
            if(this.userList[i].id === data.operatorId){
              data.operatorName = this.userList[i].userName
              break
            }
          }
          break
        case "费用":
          for (let i = 0; i < this.userList.length; i++){
            if(this.userList[i].id === data.operatorId){
              data.operatorName = this.userList[i].userName
              break
            }
          }
          break
        case "信息":
          data.reserveCarBrand = data.carInfo[0]
          data.reserveCarSeries = data.carInfo[1]
          delete data.carInfo
      }
      this.sendService(data,mod)
    },
    /**
     * 向服务器发送请求，以及回调的状态处理
     * @param data
     * @param mode
     */
    sendService(data, mode){
      new Promise((resolve, reject)=>{
        switch (mode){
          case "信息":
            this.axios.put(this.$api.publicUrl.UpdateInfo, data).then(res =>{
              if(res.data.status === 200){
                resolve("修改成功!")
                this.getOrderInfo()
              }else {
                reject(res.data.message)
              }
            })
            break
          case "发车":
            this.axios.put(this.$api.publicUrl.UpdateHandover, data).then(res =>{
              if(res.data.status === 200){
                resolve("修改成功!")
                this.getHandoverInfo()

              }else {
                reject(res.data.message)
              }
            })
            break
          case "还车":
            this.axios.put(this.$api.publicUrl.UpdateBackCar, data).then(res =>{
              if(res.data.status === 200){
                resolve("修改成功!")
                this.getBackCarInfo()
              }else {
                reject(res.data.message)
              }
            })
            break
          case "换车":
            this.axios.put(this.$api.publicUrl.UpdateReplace, data).then(res =>{
              if(res.data.status === 200){
                resolve("修改成功!")
                this.getReplaceInfo()
              }else {
                reject(res.data.message)
              }
            })
            break
          case "续租":
            this.axios.put(this.$api.publicUrl.UpdateRenewal, data).then(res =>{
              if(res.data.status === 200){
                resolve("修改成功!")
                this.getRenewalInfo()
              }else {
                reject(res.data.message)
              }
            })
            break
          case "费用":
            this.axios.put(this.$api.publicUrl.UpdateCost, data).then(res =>{
              if(res.data.status === 200){
                resolve("修改成功!")
                this.getCostInfo()
              }else {
                reject(res.data.message)
              }
            })
            break
          case "删除费用":
            this.axios.delete(this.$api.publicUrl.DelCost + "/" +data).then(res =>{
              if(res.data.status === 200){
                resolve("删除成功!")
                this.getCostInfo()
              }else {
                reject(res.data.message)
              }
            })
            break
          case "新增费用":
            this.axios.post(this.$api.publicUrl.DelCost, data).then(res =>{
              if(res.data.status === 200){
                resolve("新增成功!")
                this.getCostInfo()
              }else {
                reject(res.data.message)
              }
            })
            break
        }
      }).then(res =>{
        this.$message({
          message: res,
          type: 'success'
        })
      }).catch(err =>{
        this.$message.error(err);
      })
    },
    /**
     * 获取换车信息
     */
    getReplaceInfo(){
      this.axios.get(this.$api.publicUrl.ReplaceInfo, {
        params: {
          orderId: this.orderId
        }
      }).then(res =>{
        this.ReplaceInfo = res.data.data
      })
    },
    /**
     * 交车信息获取
     */
    getHandoverInfo(){
      this.axios.get(this.$api.publicUrl.HandoverInfo, {
        params: {
          orderId: this.orderId
        }
      }).then(res =>{
        this.HandoverInfo = res.data.data
      })
    },
    /**
     * 获取还车信息
     */
    getBackCarInfo(){
      this.axios.get(this.$api.publicUrl.BackCarInfo, {
        params: {
          orderId: this.orderId
        }
      }).then(res =>{
        this.BackCarInfo = res.data.data
      })
    },
    /**
     * 续租信息获取
     */
    getRenewalInfo(){
      this.axios.get(this.$api.publicUrl.RenewalInfo, {
        params: {
          orderId: this.orderId
        }
      }).then(res =>{
        this.RenewalInfo = res.data.data
      })
    },
    /**
     * 费用信息获取
     */
    getCostInfo(){
      this.axios.get(this.$api.publicUrl.CostInfo, {
        params: {
          orderId: this.orderId
        }
      }).then(res =>{
        this.CostInfo = res.data.data
      })
    },
    /**
     *  基本信息获取
     */
    getOrderInfo(){
      this.axios.get(this.$api.publicUrl.BasicInfo + "/" + this.orderId).then(res =>{
        this.BasicInfo = res.data.data
        this.$callback(this.$lodash.cloneDeep(this.BasicInfo))
      })
    }
  }
}
</script>

<template>
  <fragment>
    <div class="Opening">
      <section class="options">
        <Skeleton :data="BasicInfo" entry="basic" :disable="false" ti="信息" :updateCallBack="secondaryPopupCallBack"
                  :class="{'not_display': !BasicInfo}" class="Disable_expand"></Skeleton>
      </section>
      <fragment>
        <section class="options">
          <Skeleton :data="HandoverInfo" entry="start" :disable="false" ti="发车" :updateCallBack="secondaryPopupCallBack"
                    :class="{'not_display': !HandoverInfo}"></Skeleton>
        </section>
        <section class="options">
          <Skeleton :data="BackCarInfo" entry="back" :disable="false" ti="还车" :updateCallBack="secondaryPopupCallBack"
                    :class="{'not_display': !BackCarInfo}"></Skeleton>
        </section>
        <section class="options">
          <Skeleton :data="ReplaceInfo" entry="replace" :disable="true" :updateCallBack="secondaryPopupCallBack"
                    :class="{'not_display': !ReplaceInfo}"></Skeleton>
        </section>
        <section class="options">
          <Skeleton :data="RenewalInfo" entry="renewal" :disable="true" :updateCallBack="secondaryPopupCallBack"
                    :class="{'not_display': !RenewalInfo}"></Skeleton>
        </section>
        <section class="options">
          <Skeleton :data="CostInfo" entry="cost" :disable="false" ti="新增费用" :updateCallBack="secondaryPopupCallBack"
                    :class="{'not_display': !costWait}"></Skeleton>
        </section>
        <section class="options">
          <Skeleton :data="ContractInfo" entry="contract" :disable="true" :class="{'not_display': !ContractInfo}"></Skeleton>
        </section>
      </fragment>
      <fragment v-if="step !== 6">
        <div class="skeleton">
          <img src="../../assets/images/loading.gif" class="loading" width="100" height="100" alt="加载中">
        </div>
      </fragment>
    </div>
  </fragment>

</template>

<style scoped>
.skeleton{
  min-height: 100px;
  position: relative;
}
.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section{
  background-color: #F9F9F9;
}
.Opening{
  transition: all .5s;
}
.options{
  box-sizing: border-box;
  width: 800px;
}
.not_display{
  height: 0!important;
  padding: 0!important;
  margin-top: 0!important;
}
.options>div{
  padding: 10px;
}
.options:not(:first-child)>div{
  margin-top: 20px;
}
.Disable_expand{
  max-height: 1000px;
}
>>>.Disable_expand .control .expand_btn{
  width: 0;
  overflow: hidden;
  height: 0;
}
>>>.Disable_expand .control .el-button{
  margin-right: 0;
}
</style>
