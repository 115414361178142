<script>
import FormInput from "@/view/form/child/FormInput.vue";
import FormSelect from "@/view/form/child/FormSelect.vue";
import FormTextarea from "@/view/form/child/FormTextarea.vue";
import FormDatePicker from "@/view/form/child/FormDatePicker.vue";
import FormTimePicker from "@/view/form/child/FormTimePicker.vue";
import FormCascader from "@/view/form/child/FormCascader.vue";
import FormRadio from "@/view/form/child/FormRadio.vue";
export default {
  props: ["data"],
  data(){
    return{
      formData: this.$setTree.formData
    }
  },
  methods: {
    /**
     * 判断列的类型返回不同组件
     * @param type
     * @return {{props: [string]}}
     */
    determineSubComponents(type){
      switch (type){
        case "text":
          return FormInput
        case "number":
          return FormInput
        case "select":
          return FormSelect
        case "textarea":
          return FormTextarea
        case "date":
          return FormDatePicker
        case "dateTime":
          return FormTimePicker
        case "Cascader":
          return FormCascader
        case "radio":
          return FormRadio
        case "month":
          return FormDatePicker
        case "daterange":
          return FormDatePicker
      }
    },
    /**
     * 双向数据绑定
     * 子组件的回调方法，接收传递过来的值，改变源数据
     * @param key {String} 键
     * @param value 值
     */
    updateValue(key, value){
      this.formData[key] = value
    },
    /**
     * 向创建者提交表单数据之前先验证数据是否符合规则
     */
    sendFromData(){
      this.$refs['formData'].validate((valid) => {
        if (valid) {
          if(this.$parent.closePopup){
            this.$parent.closePopup()
          }
          this.$fun(this.$lodash.cloneDeep(this.formData), this.$mode)
        } else {
          this.$message.error("数据不符合要求")
          return false;
        }
      });
    }
  },
  render(h, context) {
    return h("div", {
      class: ""
    }, [
      this.$createElement("el-form", {
        ref: "formData",
        attrs:{
          model: this.formData,
          rules: this.$setTree.rules,
        }
      }, [
        this.$setTree.tree.map(row =>{
          return h("div", {
            class: "form_row"
          }, [
            row.map(col =>{
              return h("el-form-item", {
                class: col.class || null,
                attrs:{
                  label: col.label,
                  prop: col.prop,
                }
              }, [
                h(this.determineSubComponents(col.type), {
                  props: {
                    data: this.formData[col.key],
                    type: col.type,
                    bond: col.key,
                    format: col.format,
                    valueFormat: col.valueFormat,
                    options: col.options,
                    pickerOptions: col.pickerOptions
                  },
                  on: {
                    sendValue: this.updateValue
                  }
                })
              ])
            })
          ])
        })
      ]),
      h("el-button", {
        class: "popup_submit",
        attrs:{
          type: "success",
          size: "small"
        },
        on:{
          click: this.sendFromData
        }
      },"提交")
    ])
  }
}
</script>

<style scoped>
.form_row{
  display: flex;
}
.form_row>.el-form-item{
  flex: 1;
  display: flex;
}
.popup_submit{
  float: right;
}
.el-input{
  width: 250px;
}
>>>.el-form-item>label{
  width: 100px;
}
>>>.el-form-item:nth-child(2)>label{
  margin-left: 10px;
}
>>>.el-form-item .el-select{
  width: 250px;
}
>>>.el-textarea{
  width: 500px;
}
>>>.el-input__inner::-webkit-outer-spin-button, >>>.el-input__inner::-webkit-inner-spin-button{
  -webkit-appearance: none;
}
>>>.el-input__inner[type='number']{
  -moz-appearance: textfield;
}
>>>.mini label:not(.el-radio){
  min-width: 80px;
}
>>>.mini .el-input, >>>.mini .el-textarea, >>>.mini .el-select{
  width: 100%;
}
.el-button{
}
</style>
