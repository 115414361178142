<template>
	<div class="login">
		<div class="image">
			<img style="width: 100%; height: 100%" :src="img" />
		</div>
		<div class="main">
			<el-form class="login-form" ref="loginForm" :model="loginForm" :rules="loginRules" label-position="left">
				<div class="title">
					<div class="title-one">
						<img style="width: 100%; height: 100%" :src="icon" />
					</div>
					<div class="title-two">铂程租车后台管理系统</div>
				</div>
				<el-form-item prop="username">
					<el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号"
						prefix-icon="el-icon-user">
						<i class="el-icon-user"></i>
					</el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="密码"
						@keyup.enter.native="handleLogin" prefix-icon="el-icon-lock">
					</el-input>
				</el-form-item>
				<el-checkbox v-model="loginForm.rememberMe" text-color="#358F6C" fill="#358F6C"
					style="margin: 0 0 1.5625rem 0">记住我</el-checkbox>
				<el-form-item style="width: 100%">
					<el-button :loading="loading" size="medium" type="primary" style="width: 100%"
						@click.native.prevent="handleLogin">
						<span v-if="!loading">登 录</span>
						<span v-else>登 录 中...</span>
					</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import Config from "@/settings";
	import Cookies from "js-cookie";
	import Md5 from "js-md5";
	export default {
		name: "login",
		data() {
			return {
				img: require("@/assets/images/picture.png"),
				icon: require("@/assets/images/icon.png"),
				cookiePass: "",
				loginForm: {
					identity: "",
					username: "",
					password: "",
					rememberMe: false,
				},
				loginRules: {
					// identity: [
					//   { required: true, trigger: "blur", message: "身份不能为空" },
					// ],
					username: [{
						required: true,
						trigger: "blur",
						message: "账号不能为空"
					}, ],
					password: [{
						required: true,
						trigger: "blur",
						message: "密码不能为空"
					}, ],
				},
				loading: false,
				redirect: undefined,
			};
		},
		created() {
			// this.$store.commit("clearVUEX");
			// this.$store.commit("selectMenu", {
			// 	name: "welcome"
			// });
			// this.getCookie();
			// if (this.token != null && this.token.length > 0) {
			// 	this.$router.push("/");
			// }
		},
		computed: {
			token() {
				//调用username即可   {{username}}
				return sessionStorage.getItem("sessionToken");
			},
		},
		methods: {
			getCookie() {
				const username = Cookies.get("username");
				let password = Cookies.get("password");
				const rememberMe = Cookies.get("rememberMe");
				// 保存cookie里面的加密后的密码
				this.cookiePass = password === undefined ? "" : password;
				password = password === undefined ? this.loginForm.password : password;
				this.loginForm = {
					username: username === undefined ? this.loginForm.username : username,
					password: password,
					rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
				};
			},
			handleLogin() {
				let _this = this;
				//  this.$router.push("/");

				this.$refs.loginForm.validate((valid) => {
					const user = {
						username: this.loginForm.username,
						password: this.loginForm.password,
						rememberMe: this.loginForm.rememberMe,
					};
					if (this.cookiePass && user.password !== this.cookiePass) {
						// user.password = encrypt(user.password)
						user.password = this.cookiePass;
					}
					if (valid) {
						this.loading = true;
						if (user.rememberMe) {
							Cookies.set("username", user.username, {
								expires: Config.passCookieExpires,
							});
							Cookies.set("password", user.password, {
								expires: Config.passCookieExpires,
							});
							Cookies.set("rememberMe", user.rememberMe, {
								expires: Config.passCookieExpires,
							});
						} else {
							Cookies.remove("username");
							Cookies.remove("password");
							Cookies.remove("rememberMe");
						}
						let hostUrl = _this.$api.publicUrl.UserLogin;
						let requestData = {
              userAccount: user.username,
							password: Md5(user.password),
						};
						_this.axios
							.post(hostUrl, requestData)
							.then((response) => {
								_this.loading = false;
								if (response.data.status === 200) {
									let token = response.data.data.token;
									// this.$store.commit("setToken", token);
									sessionStorage.setItem("sessionToken", token);
									_this.$router.push("/");
                  this.$store.commit("setUser", {
                    userName: response.data.data.userName,
                    account: response.data.data.account,
                  })
								} else {
									_this.$message.warning(response.data.message);
								}
							})
							.catch((error) => {
								_this.loading = false;
								_this.$message.error("登录失败，error:" + "账号或密码错误！");
							});
					} else {
						return false;
					}
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.image {
		width: 68%;
		height: 100%;
	}
  .login{
    height: 100%;
    width: 100%;
    display: flex;
  }
  .main{
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
	.title-one {
		width: 4.4375rem;
		height: 2.125rem;
		// background: url(../assets/images/icon.png);
		background-color: #fff;
	}

	.title-two {
		font-size: 1.5rem;
		font-weight: 400;
		color: #358f6c;
		padding: 1.0625rem 0 2.5rem 0;
	}

	.shenfen {
		width: 100%;

		.el-input__inner {
      //引入icon
			background-size: 1.0625rem 0.8125rem;
      background: url("~@/assets/images/shenfen@2x.png") no-repeat 0.5rem 0.6875rem;
      padding: 0 0 0 2rem; //需要设置padding 把placeholder向右移
			box-sizing: border-box;
			font-size: 0.875rem;
		}
	}

	.el-checkbox__input.is-focus .el-checkbox__inner {
		//   background-color: #358f6c !important;
		border-color: #358f6c !important;
	}

	.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #358f6c !important;
		border-color: #358f6c !important;
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #358f6c !important;
		border-color: #358f6c !important;
	}

	.el-select .el-input.is-focus .el-input__inner {
		border-color: #358f6c !important;
	}

	.el-input__inner:focus {
		border-color: #358f6c !important;
	}
</style>
