<script>
export default {
  props: ["data", "bond", "type", "format", "valueFormat", "pickerOptions"],
  data(){
    return {
      value: this.data
    }
  },
  methods:{
    handleChange(value){
      this.$emit('sendValue', this.bond, value)
    }
  }
}
</script>

<template>
  <el-date-picker
    v-model="value"
    :type= type
    :format= format
    :value-format="valueFormat"
    placeholder="选择日期"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    :picker-options="pickerOptions"
    @input="handleChange">
  </el-date-picker>
</template>

<style scoped lang="less">

</style>
