var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[_c('div',{staticClass:"main"},[_c('div',{staticClass:"items"},[_c('p',{staticClass:"title tx"},[_vm._v("费用记录")])]),_c('div',{staticClass:"items"},[_c('p',[_vm._v("费用项数："),_c('span',[_vm._v(_vm._s(_vm.data? _vm.data.length : "无"))])]),_c('p',[_vm._v("总费用："+_vm._s(_vm.totalCost))]),_c('p',[_vm._v("未到账："+_vm._s(_vm.arrears))])])]),(_vm.data)?_c('div',{staticClass:"detail segment"},[_c('Tabulate',{attrs:{"data":{
      columnName: [
        {name: '费用名称', prop: 'costName', type: 'text', needSort: false, style: 'flex: 1.5'},
        {name: '费用金额', prop: 'costAmount', type: 'text', needSort: false, style: 'min-width: 100px; flex: 0'},
        {name: '支付状态', prop: 'costStatus', type: 'text', needSort: false, style: 'min-width: 100px; flex: 0', format: 'paymentStatus'},
        {name: '付款方式', prop: 'paymentType', type: 'text', needSort: false, style: 'min-width: 100px; flex: 0', format: 'paymentMethod'},
        {name: '创建时间', prop: 'createTime', type: 'text', needSort: false, style: 'flex: 1.5'},
      ],
      rows: this.data,
      control: [
        {name: '修改', type: 'warning', method: this.updateRow},
        {name: '删除', type: 'danger', method: this.delCost}
      ]
    }}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }