<script>
export default {
  props: ["data"],
  computed: {
    timeDifference(){
      let day = Math.trunc((Date.parse(this.data.returnCarTimeEstimate) - Date.parse(this.data.takeCarTimeEstimate))/(24 * 3600 * 1000))
      let hours = Math.trunc((Date.parse(this.data.returnCarTimeEstimate) - Date.parse(this.data.takeCarTimeEstimate))%(24 * 3600 * 1000) / (3600 * 1000))
      return `${day}天${hours}时`
    },
    /**
     * 通过还车时间，订单状态等，判断此插图的内容以及展示形式
     * @return {null|string}
     */
    illInfo() {
      let now = new Date().getTime()
      let plan = Date.parse(this.data.returnCarTimeEstimate)
      if (this.data.goodEvaluate) {
        this.$nextTick(()=>{
          this.$refs.ill.className = "illustration good"
        })
        return "已好评"
      } else if(this.data.orderStatus !== 3 && this.data.orderStatus !== 4){
        return null
      } else if (plan - now <= 0) {
        this.$nextTick(()=>{
          this.$refs.ill.className = "illustration already"
        })
        return "已超时"
      } else if (plan - now <= 60 * 1000 * 60 * 4) {
        this.$nextTick(()=>{
          this.$refs.ill.className = "illustration be_to"
        })
        return "即将超时"
      }else {
        return null
      }
    }
  }
}
</script>

<template>
  <fragment>
    <div class="main">
      <div class="items">
        <p class="title">{{data.userName}}</p>
        <p>预定车型：{{data.reserveCarBrand+"/"+data.reserveCarSeries}}</p>
      </div>
      <div class="items">
        <p>电话：{{data.phoneNumber}}</p>
        <p>预计用车时间：{{timeDifference}}</p>
      </div>
      <span ref="ill">{{ illInfo }}</span>
    </div>
    <div class="detail segment">
      <el-descriptions>
        <el-descriptions-item label="承租人">{{data.userName}}</el-descriptions-item>
        <el-descriptions-item label="预定车型">{{data.reserveCarBrand+"/"+data.reserveCarSeries}}</el-descriptions-item>
        <el-descriptions-item label="订单号">{{data.orderSn}}</el-descriptions-item>
        <el-descriptions-item label="订单车型">{{data.carInfo}}</el-descriptions-item>
        <el-descriptions-item label="订单来源">{{data.orderSource}}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{data.phoneNumber}}</el-descriptions-item>
        <el-descriptions-item label="保险">{{data.orderInsurance}}</el-descriptions-item>
        <el-descriptions-item label="预定取车时间">{{data.takeCarTimeEstimate}}</el-descriptions-item>
        <el-descriptions-item label="预定还车时间">{{data.returnCarTimeEstimate}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </fragment>
</template>

<style src="@/assets/css/Popup.css" scoped></style>
