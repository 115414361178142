<script>
import Tabulate from "@/view/tabulate/Tabulate.vue";

export default {
  components: {Tabulate},
  props: ["data", "updateCallBack"],
  methods:{
    updateRow(row){
      let {certificateImage,createTime,operatorId,operatorName,orderId,returnTimeAfter,returnTimeBefore, ...rest} = row
      this.createDrawer(this.$lodash.cloneDeep(rest))
    },
    /**
     * 创建二级弹出层Drawer, 以及创建数据模板
     */
    createDrawer(data){
      this.$createSecondaryPopup({
          ti: "续租记录修改",
          tree: [
            [{label: "费用", type: "text", key: "chargeAmount", prop: "chargeAmount", class: "mini"}],
            [{label: "备注", type: "textarea", key: "remark", prop: "remark", class: "mini"}],
          ],
          formData: data,
          rules: {
            chargeAmount: [{ required: true, message: '请输入费用', trigger: 'blur' }],
          }
        }
        , "续租", this.updateCallBack)
    }
  }
}
</script>

<template>
  <fragment v-if="data">
    <div class="main">
      <div class="items">
        <p class="title tx">续租记录</p>
      </div>
      <div class="items">
        <p>续租次数：<span>{{data.length}}</span></p>
      </div>
    </div>
    <div class="detail segment scroll">
      <Tabulate :data="{
        columnName: [
          // {name: '续租费用', prop: 'chargeAmount', type: 'text', needSort: false, style: 'min-width: 100px; flex: 0'},
          {name: '续租前还车时间', prop: 'returnTimeBefore', type: 'text', needSort: false, style: 'min-width:180px'},
          {name: '续租后还车时间', prop: 'returnTimeAfter', type: 'text', needSort: false, style: 'min-width:180px'},
          {name: '续租截图', prop: 'certificateImage', type: 'img', needSort: false, style: 'min-width: 100px; flex: 0'},
          {name: '操作时间', prop: 'createTime', type: 'text', needSort: false, style: 'min-width:180px'},
          {name: '备注', prop: 'remark', type: 'text', needSort: false},
        ],
        rows: this.data,
        control: [
          // {name: '修改', type: 'warning', method: this.createDrawer},
        ]
      }"></Tabulate>
    </div>
  </fragment>
</template>

<style src="@/assets/css/Popup.css" scoped></style>
