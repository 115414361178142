<script>
import TabText from "@/view/tabulate/child/TabText.vue";
import TabControl from "@/view/tabulate/child/TabControl.vue";
import TabSwitch from "@/view/tabulate/child/TabSwitch.vue";
import TabTexts from "@/view/tabulate/child/TabTexts.vue";
import TabTextsPlus from "@/view/tabulate/child/TabTextsPlus.vue";
import TabImg from "@/view/tabulate/child/TabImg.vue";

export default {
  props: ["index", "data", "rule", "control"],
  data(){
    return {
      tabData: this.data
    }
  },
  watch: {
    data(value){
      this.tabData = value
    }
  },
  methods: {
    /**
     *
     * @param type
     * @return {{props: [string]}}
     */
    filterComponent(type){
      switch (type){
        case "text":
          return TabText
        case "switch":
          return TabSwitch
        case "texts":
          return TabTexts
        case "textsPlus":
          return TabTextsPlus
        case "img":
          return TabImg
      }
    },
    /**
     * 数据预处理; 分发数据之前预处理一下
     * @param data
     * @return {{}|*}
     */
    dataPreprocessing(data){
      if(typeof data === "string"){
        return this.tabData[data]
      }else if(Array.isArray(data)){
        return data.map(item => this.tabData[item])
      }
    },
    /**
     * 双向数据绑定
     * 子组件的回调方法，接收传递过来的值，改变源数据
     * @param key {String} 键
     * @param value 值
     */
    updateValue(key, value){
      this.tabData[key] = value
      this.sendModifyData()
    },
    /**
     * 向创建者提交修改数据请求
     */
    sendModifyData(){
      this.$parent.$emit("updateStatus", {
        ...this.tabData
      }, this.backtrack)
    },
    /**
     * 数据回溯
     */
    backtrack(key){
      this.tabData[key] = this.tabData[key] === 0? 1 : 0
    }
  },
  render(h, context) {
    return h("tr", [
      h("td", this.index),
      this.rule.map(item =>{
        return h("td", {
          style: item.style
        }, [
          h(this.filterComponent(item.type), {
            props: {
              data: this.dataPreprocessing(item.prop),
              bond: item.prop,
              format: item.format
            },
            on: {
              sendValue: this.updateValue
            }
          })
        ])
      }),
      h("td", [
        h("div", {
          class: "control"
        }, [
          this.control.map(item =>{
            return h(TabControl, {
              props: {
                data: this.tabData,
                control: item
              }
            })
          })
        ])
      ])
    ])
  }
}
</script>
<style scoped>
.timed_out::after{
  content: "已超时";
  position: absolute;
  color: rgba(255, 0, 0, 0.4);
  font-size: 20px;
  letter-spacing: 10px;
  left: 120px;
  transform: rotate(-15deg) ;
  border: 1px solid rgba(255, 0, 0, 0.4);
}
.about_to_time_out::after{
  content: "即将超时";
  position: absolute;
  color: rgba(255, 136, 0, 0.4);
  font-size: 20px;
  letter-spacing: 4px;
  left: 120px;
  transform: rotate(-15deg) ;
  border: 1px solid rgba(255, 136, 0, 0.4);
}
</style>
