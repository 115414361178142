import { render, staticRenderFns } from "./ReplaceDetails.vue?vue&type=template&id=39085e49&scoped=true"
import script from "./ReplaceDetails.vue?vue&type=script&lang=js"
export * from "./ReplaceDetails.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/Popup.css?vue&type=style&index=0&id=39085e49&prod&scoped=true&lang=css&external"
import style1 from "./ReplaceDetails.vue?vue&type=style&index=1&id=39085e49&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39085e49",
  null
  
)

export default component.exports