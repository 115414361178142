<script>
  import TabTr from "@/view/tabulate/child/TabTr.vue";

  export default {
    props: ["data"],
    computed: {
      makingRule(){
        let arr = []
        this.data.columnName.forEach(col =>{
          let {name, needSort, ...rest} = col
          arr.push(rest)
        })
        return arr
      }
    },
    render(h, context) {
      return h("table", [
        h("tr", [
          h("th" , "编号"),
          this.data.columnName.map(col =>{
            return h("th", {
              style: col.style
            }, [
              col.name,
              col.needSort? [
                h("span", {
                  class: "wrapper",
                }, [
                  h("i", {
                    class: "sort rise"
                  }),
                  h("i", {
                    class: "sort decline"
                  }),
                ])
              ] : null
            ])
          })
        ]),
        this.data.rows.map((row, index) =>{
          return h(TabTr, {
            props: {
              index: index + 1,
              data: row,
              rule: this.makingRule,
              control: this.data.control,
            }
          })
        }),
      ])
    }
  }
</script>

<style scoped>
  table{
    width: 100%;
    position: relative;
    border-spacing: 0
  }
  tr{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: all 0.3s;
  }
  tr:nth-child(odd){
    background-color: #F9F9F9;
  }
  >>>tr td{
    min-height: 45px;
  }
  >>>tr td p{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  >>>tr td, >>>tr th{
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  >>>tr>:first-child{
    width: 55px;

  }
  th{
    color: #666666;
    font-weight: 500;
    user-select: none;
  }
  .wrapper{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 34px;
    width: 24px;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
  }
  .sort{
    width: 0;
    height: 0;
    border: 5px solid transparent;
    position: absolute;
    left: 7px;
  }
  .rise{
    border-bottom-color: #c0c4cc;
    top: 5px;
  }
  .decline{
    border-top-color: #c0c4cc;
    bottom: 7px;
  }
  tr:first-child{
    position: sticky;
    top: 0;
    z-index: 2;
    pointer-events: none;
  }
  tr th:not(:first-child), >>>tr td:not(:first-child):not(:last-child){
    flex: 1;
    text-align: left;
    min-width: 150px;
  }
  >>>tr td:last-child{
    position: sticky;
    width: 0;
    right: 0;
    padding: 0;
  }
  >>>tr:hover{
    background-color: #dedede!important;
  }
  >>>tr:hover .control{
    opacity: 1;
    pointer-events: auto;
  }
  >>>.control{
    position: absolute;
    right: 0;
    display: flex;
    opacity: 0;
    padding: 0 20px;
    transition: opacity 0.3s;
    pointer-events: none;
    background-color: #dedede;
  }
</style>
