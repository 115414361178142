<script>
export default {
  props: ["data", "updateCallBack"],
  methods: {
    updateRow(row){
      let {carChangeTime, carInfoAfter,carInfoBefore,carTypeAfter,checkImagesAfter,
        checkImagesBefore,createTime,operatorId,operatorName,orderId, ...rest} = row
      this.createDrawer(this.$lodash.cloneDeep(rest))
    },
    /**
     * 创建二级弹出层Drawer, 以及创建数据模板
     */
    createDrawer(data){
      this.$createSecondaryPopup({
          ti: "换车记录修改",
          tree: [
            [{label: "更换前里程", type: "text", key: "mileageBefore", prop: "mileageBefore", class: "mini"}],
            [{label: "更换前行车证", type: "radio", key: "drivingLicenseBefore", prop: "drivingLicenseBefore", class: "mini"}],
            [{label: "更换后里程", type: "text", key: "mileageAfter", prop: "mileageAfter", class: "mini"}],
            [{label: "更换后行车证", type: "radio", key: "drivingLicenseAfter", prop: "drivingLicenseAfter", class: "mini"}],
            [{label: "备注", type: "textarea", key: "changeRemark", prop: "changeRemark", class: "mini"}],
          ],
          formData: data,
          rules: {
            mileageBefore: [{ required: true, message: '请输入公里数', trigger: 'blur' }],
            drivingLicenseBefore: [{ required: true, message: '请输入行车证', trigger: 'blur' }],
            mileageAfter: [{ required: true, message: '请输入公里数', trigger: 'blur' }],
            drivingLicenseAfter: [{ required: true, message: '请输入行车证', trigger: 'blur' }],
          }
        }
        , "换车", this.updateCallBack)
    },
    formatImages(img){
      return JSON.parse(img).oil_image
    },
    formatVideo(v){
      return JSON.parse(v).verification_video
    },
    expandEvent(){
      this.$nextTick(()=>{
        let video = document.querySelectorAll(".tab_video")
        for (let i = 0; i < video.length; i++){
          video[i].addEventListener("play", function (){
            this.webkitEnterFullScreen()
          })
        }
      })
    }
  },
}
</script>

<template>
  <fragment v-if="data">
    <div class="main">
      <div class="items">
        <p class="title tx">换车记录</p>
      </div>
      <div class="items">
        <p>换车次数：<span>{{data.length}}</span></p>
      </div>
    </div>
    <div class="detail segment">
      <el-table
        :data="data"
        style="width: 100%"
        @expand-change="expandEvent">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <div class="replace_before">
                <el-form-item label="公里数">
                  <span>{{ props.row.mileageBefore + "km" }}</span>
                </el-form-item>
                <el-form-item label="行车证">
                  <span>{{ props.row.drivingLicenseBefore? "有" : "无" }}</span>
                </el-form-item>
                <el-form-item label="油量照片">
                  <el-image
                    style="width: 30px; height: 30px"
                    :src="formatImages(props.row.checkImagesBefore)"
                    :preview-src-list="[formatImages(props.row.checkImagesBefore)]">
                  </el-image>
                </el-form-item>
                <el-form-item label="验车视频" class="_video">
                  <video :src="formatVideo(props.row.checkImagesBefore)" controls width="170px" height="100px" class="tab_video"></video>
                </el-form-item>
              </div>
              <div class="replace_after">
                <el-form-item label="公里数">
                  <span>{{ props.row.mileageAfter + "km"}}</span>
                </el-form-item>
                <el-form-item label="行车证">
                  <span>{{ props.row.drivingLicenseAfter? "有" : "无" }}</span>
                </el-form-item>
                <el-form-item label="油量照片">
                  <el-image
                    style="width: 30px; height: 30px"
                    :src="formatImages(props.row.checkImagesAfter)"
                    :preview-src-list="[formatImages(props.row.checkImagesAfter)]">
                  </el-image>
                </el-form-item>
                <el-form-item label="验车视频" style="display: flex; flex-direction: column">
                  <video :src="formatVideo(props.row.checkImagesBefore)" controls width="170px" height="100px" class="tab_video"></video>
                </el-form-item>
              </div>

              <div class="update_btn">
                <el-button type="success" size="mini" @click="updateRow(props.row)">修改</el-button>
                <el-form-item label="备注">
                  <span>{{ props.row.changeRemark}}</span>
                </el-form-item>
              </div>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          label="记录ID"
          prop="id">
        </el-table-column>
        <el-table-column
          label="更换前"
          prop="carInfoBefore">
        </el-table-column>
        <el-table-column
          label="更换后"
          prop="carInfoAfter">
        </el-table-column>
        <el-table-column
          label="更换时间"
          prop="carChangeTime">
        </el-table-column>
      </el-table>
    </div>
  </fragment>
</template>

<style src="@/assets/css/Popup.css" scoped></style>

<style scoped>
>>>.demo-table-expand{
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.replace_after, .replace_before, .update_btn{
  display: flex;
  flex-direction: column;
  position: relative;
}
.replace_after::before{
  content: "换车后";
  position: absolute;
  top: -15px;
  text-align: center;
  display: block;
  width: 100%;
  font-weight: 600;
  color: #358f6c;
}
.replace_before::before{
  content: "换车前";
  position: absolute;
  top: -15px;
  text-align: center;
  display: block;
  width: 100%;
  font-weight: 600;
  color: #358f6c;
}
>>>.demo-table-expand .el-form-item{
  margin: 0;
}
.update_btn{
  width: 200px;
}
.update_btn button{
  width: 60px;
}
.update_btn span{
  display: inline-block;
  line-height: 16px;
  white-space: pre-line;
}
._video{
  width: 100px;
  height: 100px;
}
</style>
