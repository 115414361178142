<script>
import {value} from "lodash/seq";

export default {
  props: ["data"],
  computed: {
    handleImages(){
      return JSON.parse(this.data.checkCarImages)
    }
  },
  methods:{
    expandEvent(){
      this.$nextTick(()=>{
        let video = document.querySelectorAll(".star_video")
        for (let i = 0; i < video.length; i++){
          video[i].addEventListener("play", function (){
            this.webkitEnterFullScreen()
          })
        }
      })
    }
  },
  watch:{
    data(value){
      if(value){
        this.expandEvent()
      }
    }
  }
}
</script>

<template>
  <fragment v-if="data">
    <div class="main">
      <div class="items">
        <p class="title tx">发车信息</p>
        <p>用车里程：{{data.verificationMileage + "km"}}</p>
      </div>
      <div class="items">
        <p>实际车辆：{{data.carInfo}}</p>
        <p>发车时间：{{data.handTime}}</p>
      </div>
    </div>
    <div class="detail segment">
      <el-descriptions>
        <el-descriptions-item label="行车证">{{data.drivingLicense? "有" : "无"}}</el-descriptions-item>
        <el-descriptions-item label="身份证照片">
          <el-image
            style="width: 30px; height: 30px"
            :src="handleImages.identity_card_images"
            :preview-src-list="[handleImages.identity_card_images]">
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="油量照片">
          <el-image
            style="width: 30px; height: 30px"
            :src="handleImages.oil_image"
            :preview-src-list="[handleImages.oil_image]">
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="车损照片">
          <el-image
            style="width: 30px; height: 30px"
            :src="handleImages.damage_images[0]"
            :preview-src-list="handleImages.damage_images">
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="违章押金凭证">
          <el-image
            style="width: 30px; height: 30px"
            :src="handleImages.violation_deposit_image"
            :preview-src-list="[handleImages.violation_deposit_image]">
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="租车押金凭证">
          <el-image
            style="width: 30px; height: 30px"
            :src="handleImages.rent_deposit_image"
            :preview-src-list="[handleImages.rent_deposit_image]">
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="验车视频" style="flex-direction: column">
          <video v-if="handleImages.verification_video" :src="handleImages.verification_video" controls width="170px" height="100px" class="star_video"></video>
        </el-descriptions-item>
        <el-descriptions-item label="备注">{{data.remark}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </fragment>
</template>

<style src="@/assets/css/Popup.css" scoped></style>
