import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/home.vue";
import Login from "@/components/login.vue";

// 配置
Vue.use(VueRouter);
const routes = [
  { path: "/", redirect: "/home" },
  { path: "/login", meta: { title: "登录" }, component: Login },
  // 访问home时，重定向到welcome页
  {
    path: "/home",
    name: "home",
    component: Home,
    redirect: "/order/order_all",
    children: [
      {
        path: "/vehicle",
        name: "vehicle",
        component: ()=>import('../view/vehicle/VehicleManagement.vue'),
        redirect: "/vehicle/ve_tab",
        meta: { title: "车辆管理"},
        children: [
          {
            path: "ve_tab",
            name: "vehicleList",
            component: ()=>import("../view/vehicle/child/VehicleList.vue")
          },
          {
            path: "ve_mt",
            name: "MainTenance",
            component: ()=>import("../view/vehicle/child/MainTenance.vue")
          },
          {
            path: "ve_pt",
            name: "position",
            component: ()=>import("../view/vehicle/child/positioning.vue")
          }
        ]
      },
      {
        path: "/order",
        name: "order",
        component: ()=>import('../view/orderList/OrderList.vue'),
        redirect: "/order/order_all",
        meta: { title: "订单管理"},
        children: [
          {
            path: "order_all",
            name: "order_all",
            component: ()=> import("../view/orderList/child/AllOrders.vue"),
            meta: { title: "全部订单"},
          },
          {
            path: "order_transfer",
            name: "order_transfer",
            component: ()=> import("../view/orderList/child/TransferOrders.vue"),
            meta: { title: "换车记录"},
          },
          {
            path: "order_renewal",
            name: "order_renewal",
            component: ()=> import("../view/orderList/child/RenewalOrders.vue"),
            meta: { title: "续租记录"},
          }
        ]
      },
      {
        path: "/statistics",
        name: "statistics",
        component: ()=>import("../view/statistics/Statistics.vue"),
        redirect: "/statistics/order",
        meta: { title: "统计"},
        children: [
          {
            path: "order",
            name: "stat_order",
            component: ()=>import("../view/statistics/child/OrderStat.vue"),
            meta: { title: "订单统计"},
          },
          {
            path: "expense",
            name: "stat_expense",
            component: ()=>import("../view/statistics/child/ExpenseStat.vue"),
            meta: { title: "费用统计"},
          },
          {
            path: "inventory",
            name: "inventory",
            component: ()=>import("../view/statistics/child/InventoryStat.vue"),
            meta: { title: "库存统计"},
          },
          {
            path: "vehicle",
            name: "stat_vehicle",
            component: ()=>import("../view/statistics/child/VehicleStat.vue"),
            meta: { title: "车辆统计"},
          }
        ]
      },
      {
        path: "/configuration",
        name: "configuration",
        component: () =>import("../view/configuration/SystemConfiguration.vue"),
        redirect: "/configuration/black",
        meta: { title: "系统配置"},
        children:[
          {
            path: "black",
            name: "black",
            component: ()=>import('../view/configuration/child/BlackList.vue'),
            meta: { title: "黑名单" },
          },
          {
            path: "dict",
            name: "dict",
            component: ()=>import("../view/configuration/child/Dictionary.vue"),
            meta: { title: "字典" },
          },
          {
            path: "expenses",
            name: "expenses",
            component:()=>import("../view/configuration/child/ExpenseItem.vue"),
            meta: { title: "费用项" },
          },
        ]
      },
      {
        path: "/user",
        name: "user",
        meta: { title: "用户管理"},
        redirect: "/user/u_list",
        component: () =>import ("../view/user/UserManage.vue"),
        children: [
          {
            path: "u_list",
            name: "u_list",
            component:()=>import("../view/user/child/UserList.vue"),
            meta: { title: "用户列表" },
          },
        ]
      }
    ],
  },
];
// const originalPush = VueRouter.prototype.push
// //修改原型对象中的push方法
// VueRouter.prototype.push = function push(location) {
//    return originalPush.call(this, location).catch(err => err)
// }
const router = new VueRouter({
  routes,
});

/**
 * 挂载路由守卫
 * to 代表将要访问的路径
 * from 代表从哪个路径跳转而来
 * next 是一个函数，next() 放行。   next('/login') 强制跳转
 */
// router.beforeEach((to, from, next) => {
//   console.log(to, from)
//   next();
// });
//
//
//
// // 挂载路由守卫 - 访问结束
// router.afterEach(() => {
//
// });

export default router;
