<script>
export default {
  props: ["data", "bond"],
  data(){
    return {
      value: this.data
    }
  },
  methods:{
    handleChange(value){
      this.$emit('sendValue', this.bond, value)
    }
  }
}
</script>

<template>
  <el-date-picker
    v-model="value"
    type="datetime"
    value-format="yyyy-MM-dd HH:mm:ss"
    placeholder="选择日期时间"
    @input="handleChange">
  </el-date-picker>
</template>

<style scoped lang="less">

</style>
