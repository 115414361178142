import Vue from "vue";
import SecondaryPopup from "@/components/SecondaryPopup.vue";
import pubSub from "pubsub-js"
/**
 * 创建二级弹出层,将封装的组件挂载到一级弹出层上
 * @param setTree {Object} 集合树
 * @param mode {String} 方式
 * @param fun {function} 回调方法
 */
export default function (setTree, mode, fun){
  Vue.prototype.$setTree = setTree
  Vue.prototype.$mode = mode
  Vue.prototype.$fun = fun
  if(!document.querySelector(".drawer")) {
    let div = document.createElement("div")
    div.id = "popup_drawer"
    document.querySelector(".popup").querySelector(".content").appendChild(div)
    new Vue({
      render: h => h(SecondaryPopup),
    }).$mount("#popup_drawer")
  }else {
    pubSub.publish("load")
  }
}
