<script>
import pubSub from "pubsub-js"
import AddAndModify from "@/view/form/AddAndModify.vue";
import QueryOrder from "@/view/queryOrder/QueryOrder.vue";

export default {
  data(){
    return{
      loading: false
    }
  },
  methods:{
    importTemplate(el, h){
      if (this.loading) return
      switch (el){
        case "form":
          return h(AddAndModify)
        case "queryOrder":
          return h(QueryOrder)
      }
    },
    closePop(){
      document.querySelector(".drawer").style.cssText = `right: 0; overflow: hidden`
    }
  },
  beforeUpdate() {
    document.querySelector(".drawer").style.cssText = `right: -300px;`
  },
  render(h) {
    return h("div", {
      class: "drawer",
    }, [
      h("div", {
        class: "content"
      }, [
        h("div", {
          class: "title"
        }, [
          h("h2", this.$setTree.ti),
          h("div", {
            class: "close",
            on: {
              click: this.closePop
            }
          })
        ]),
        h("section", {
          class: "custom"
        }, [
          this.importTemplate("form", h)
        ])
      ]),
      h("div", {
        class: {
          "loading": true,
          on: this.loading
        }
      }, [
        h("div",{

        })
      ])
    ])
  },
  mounted() {
    setTimeout(()=>{
      document.querySelector(".drawer").style.right = "-300px"
    },100)
    this.s = pubSub.subscribe("load", ()=>{
      this.loading = true
      setTimeout(()=>{
        this.loading = false
      },500)
      this.$forceUpdate()
      this.$children[0].formData = this.$setTree.formData
      this.$children[0].$forceUpdate()

    })
    this.x = pubSub.subscribe("dis", ()=>{
      this.$destroy()
    })
  },
  beforeDestroy() {
    pubSub.unsubscribe(this.s)
    pubSub.unsubscribe(this.x)
  }
}
</script>


<style scoped lang="less">
.drawer{
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 100%;
  z-index: -1;
  transition: all 0.3s;
  background-color: white;
}
.title{
  text-align: left;
  background-color: #358f6c;
  color: white;
  padding: 5px 20px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
}
.custom{
  background-color: white;
  padding: 20px 10px 20px 10px;
  max-height: 700px;
  overflow-y: scroll;
  position: relative;
}
.close:hover{
  transform: rotate(360deg);
}
.loading{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: white;
  display: none;
}
.on{
  display: block;
}
.close{
  height: 50px;
  width: 50px;
  background: url("../assets/images/close.png") no-repeat;
  background-size: cover;
  cursor: pointer;
  transform: rotate(0);
  transition: transform 1s;
}
.loading>div{
  background-image: url("../assets/images/loading.gif");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/deep/.mini label:not(.el-radio){
  min-width: 110px;
}
</style>
