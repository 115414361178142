<script>
export default {
  props: ["data"],
  computed: {
    timeDifference(){
      let day = Math.trunc((Date.parse(this.data[1]) - Date.parse(this.data[0]))/(24 * 3600 * 1000))
      let hours = Math.trunc((Date.parse(this.data[1]) - Date.parse(this.data[0]))%(24 * 3600 * 1000) / (3600 * 1000))
      return `${day}天${hours}时`
    }
  },
  watch:{
    data:{
      handler(value) {
        const state = this.$parent.tabData.orderStatus
        let now = new Date().getTime()
        let plan = Date.parse(value[1])
        this.$nextTick(()=> {
          this.$el.parentElement.parentElement.className = null
        })
        if(state !== 3 && state !== 4){
          return
        }
        if(plan - now <= 0){
          this.$nextTick(()=>{
            this.$el.parentElement.parentElement.classList.add("timed_out")
          })
        } else if(plan - now <= 60 * 1000 * 60 * 4) {
          this.$nextTick(()=> {
            this.$el.parentElement.parentElement.classList.add("about_to_time_out")
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<template>
<div class="texts_plus">
  <i class="icon_q">取</i>
  <span class="station"></span>
  <span class="time">{{data[0]}}</span>
  <div class="due_time">
    <span class="zu">{{timeDifference}}</span>
    <i class="b_img">
      <img src="../../../assets/images/doubleArrow.png" alt="双向箭头"/>
    </i>
  </div>
  <i class="icon_h">还</i>
  <span class="station"></span>
  <span class="time">{{data[1]}}</span>
</div>
</template>

<style scoped lang="less">
.texts_plus{
  width: 100%;
  display: flex;
  align-items: center;
}
.time{
  width: 90px;
  text-align: center;
  font-size: 14px;
  color: #666666;
}
.texts_plus>i{
  color: white;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  text-align: center;
  font-style: inherit;
  font-size: 14px;
}
.icon_q{
  background-color: #FF6600;
}
.icon_h{
  background-color: #999999;
}
.b_img{
  width: 100%;
  display: inline-block;
}
.b_img img{
  width: 100%;
  height: 17px;

}
.zu{
  color: #333333;
  font-size: 12px;

}
.due_time{
  text-align: center;
  width: 75px;
  transform: translate(-5px, -7px);
}
.station{
  flex-grow: 1;
}
</style>
