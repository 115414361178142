<script>
export default {
  props: ["data"],
  computed: {
    handleImages(){
      return JSON.parse(this.data.checkCarImages)
    }
  }
}
</script>

<template>
  <fragment v-if="data">
    <div class="main">
      <div class="items">
        <p class="title tx">还车记录</p>
        <p>还车里程：{{data.verificationMileage + "km"}}</p>
      </div>
      <div class="items">
        <p>实际车辆：{{data.carInfo}}</p>
        <p>还车时间：{{data.handTime}}</p>
      </div>
    </div>
    <div class="detail segment">
      <el-descriptions>
        <el-descriptions-item label="行车证">{{data.drivingLicense? "有" : "没有"}}</el-descriptions-item>
        <el-descriptions-item label="油量照片">
          <el-image
            style="width: 30px; height: 30px"
            :src="handleImages.oil_image"
            :preview-src-list="[handleImages.oil_image]">
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="备注">{{data.remark}}</el-descriptions-item>
        <el-descriptions-item label="验车视频">
          <video v-if="handleImages.verification_video" :src="handleImages.verification_video" controls width="170px" height="100px" class="star_video"></video>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </fragment>
</template>

<style src="@/assets/css/Popup.css" scoped></style>
