let menuData = function() {
  return [{
    name: "order",
    label: "全部订单",
    icon: require('@/assets/images/orderIcon/quanbudingdan.png'),
    icons: require('@/assets/images/orderIcon/quanbu2.png'),
    path: "/order",
  },];
};
let carMenu = () => {
  return [
    {
      name: "vehicle",
      label: "车辆列表",
      icon: require('@/assets/images/orderIcon/quanbudingdan.png'),
      icons: require('@/assets/images/orderIcon/quanbu2.png'),
      path: "/vehicle",
    },
    {
      name: "conserve",
      label: "保养",
      icon: require('@/assets/images/orderIcon/quanbudingdan.png'),
      icons: require('@/assets/images/orderIcon/quanbu2.png'),
      path: "/conserve",
    }]
}
let shopMenu = () => {
  return [
    {
      name: "orderStatistics",
      label: "订单统计",
      icon: require('@/assets/images/orderIcon/quanbudingdan.png'),
      icons: require('@/assets/images/orderIcon/quanbu2.png'),
      path: "/orderStat",
    },
    {
      name: "costStatistics",
      label: "费用统计",
      icon: require('@/assets/images/orderIcon/quanbudingdan.png'),
      icons: require('@/assets/images/orderIcon/quanbu2.png'),
      path: "/costStat",
    }
  ]
}
let black = () => {
  return [
    {
      name: "black",
      label: "黑名单",
      icon: require('@/assets/images/orderIcon/quanbudingdan.png'),
      icons: require('@/assets/images/orderIcon/quanbu2.png'),
      path: "/configuration/black",
    },
    {
      name: "configurations",
      label: "数据字典",
      icon: require('@/assets/images/orderIcon/quanbudingdan.png'),
      icons: require('@/assets/images/orderIcon/quanbu2.png'),
      path: "/configuration/configurations",
    },
    {
      name: "configurations",
      label: "费用项",
      icon: require('@/assets/images/orderIcon/quanbudingdan.png'),
      icons: require('@/assets/images/orderIcon/quanbu2.png'),
      path: "/configuration/configurations",
    }
  ]
}
let user = () =>{
  return [
    {
      name: "userList",
      label: "用户列表",
      icon: require('@/assets/images/orderIcon/quanbudingdan.png'),
      icons: require('@/assets/images/orderIcon/quanbu2.png'),
      path: "",
    }
  ]
}
export default {
	menuData,
	carMenu,
	shopMenu,
	black
};
