<script>
export default {
  props: ["data", "type", "bond"],
  methods:{
    handleValue(value){
      this.$emit('sendValue', this.bond, value)
    }
  }
}
</script>

<template>
  <el-input :type="type" placeholder="请输入内容" :value="data" @input="handleValue" maxlength="100" show-word-limit></el-input>
</template>

