<script>
export default {
  props:["data"],
  computed: {
    signStatus(){
      switch (this.data.signStatus){
        case 1:
          return "签署中"
        case 2:
          return "签署完成"
        case 3:
          return "失败"
        case 4:
          return "拒签"
        default:
          return "未知"
      }
    },
    signFlowStatus(){
      switch (this.data.signFlowStatus){
        case 0:
          return "草稿"
        case 1:
          return "签署中"
        case 2:
          return "完成"
        case 3:
          return "撤销"
        case 5:
          return "过期"
        case 7:
          return "拒签"
        default:
          return "未知"
      }
    },
  },
  methods: {
    formatTime(time) {
      if (!time){
        return null
      }
      let date = new Date(time);
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
      let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
      let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
      return  Y + M + D + h + m;
    }
  }
}
</script>

<template>
<fragment v-if="data">
  <div class="main">
    <div class="items">
      <p class="title tx">电子合同</p>
      <p>合同类型：{{data.contractType? "租车合同" : "换车合同"}}</p>
    </div>
    <div class="items">
      <p>合同签署状态：{{signStatus}}</p>
      <p>签署流程状态：{{signFlowStatus}}</p>
    </div>
  </div>
  <div class="detail segment">
    <el-descriptions>
      <el-descriptions-item label="订单编号">{{data.orderSn}}</el-descriptions-item>
      <el-descriptions-item label="签署方姓名">{{data.signUserName}}</el-descriptions-item>
      <el-descriptions-item label="签署方身份证号">{{data.signUserNumber}}</el-descriptions-item>
      <el-descriptions-item label="签署方电话">{{data.signUserMobile}}</el-descriptions-item>
      <el-descriptions-item label="签署过期时间">{{data.signExpireTime}}</el-descriptions-item>
      <el-descriptions-item label="签署流程开启时间">{{this.formatTime(data.signStartTime)}}</el-descriptions-item>
      <el-descriptions-item label="签署流程结束时间">{{this.formatTime(data.signFinishTime)}}</el-descriptions-item>
    </el-descriptions>
  </div>
</fragment>
</template>
<style src="@/assets/css/Popup.css" scoped></style>
