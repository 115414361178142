<script>
export default {
  props: ["data"],
}
</script>

<template>
  <el-image
    style="width: 30px; height: 30px"
    :src="data"
    :preview-src-list="[data]">
  </el-image>
</template>
