import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState  from 'vuex-persistedstate'
//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
  plugins: [
    //状态持久化
    createPersistedState({
      key: 'GlobalState',
      storage: window.sessionStorage,
      reducer: (state) => {
        return {
          user: state.user,
          steps: state.steps
        }
      }
    }),
  ],
  state: {
    user: null,
    steps: {menu: 0, item: 0}
  },
  mutations: {
    setUser(state, content){
      state.user = content
    },
    delUser(state, content){
      state.user = null
    },
    updateMenu(state, content){
      state.steps.menu = content
      state.steps.item = 0
    },
    updateItem(state, content){
      state.steps.item = content
    }
  }
})

export default store
