<script>
export default {
  props: ["data"],
  computed:{
    dataFormat(){
      return this.data.join("/")
    }
  }
}
</script>

<template>
  <p>{{dataFormat}}</p>
</template>
